/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const paramsToObject = (entries) => {
  const result = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
};

const useFetch = ({
  method,
  url,
  token,
  query = null,
  headers = null,
  body = null,
  delay = 0,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params, setParams] = useState(null);

  const fetchData = useCallback(
    ({ cancelToken }) => {
      const requestHeaders = { Authorization: `Bearer ${token}` };

      const config = {
        headers: requestHeaders,
        method,
        url,
      };

      if (cancelToken) config.cancelToken = cancelToken;
      if (headers) config.headers = { ...requestHeaders, ...headers };
      if (params) config.params = params;
      if (body) config.data = body;

      axios({ ...config })
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Failed to ${method} ${url}: `, { error });
          setError({ error });
          setData(error?.response?.data);
          setLoading(false);
        });
    },
    [method, url, body, params, headers, token],
  );

  const refresh = () => {
    setLoading(true);
    const source = axios.CancelToken.source();
    setTimeout(() => fetchData({ cancelToken: source.token }), delay);
  };

  useEffect(() => {
    if (query && typeof query === 'object') setParams({ ...query });

    if (query && typeof query === 'string') {
      const urlParams = new URLSearchParams(query);
      const entries = urlParams.entries();
      const queryObject = paramsToObject(entries);
      setParams({ ...queryObject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let delayId = null;
    setLoading(true);

    if (!delay) {
      fetchData({ cancelToken: source.token });
    } else {
      delayId = setTimeout(
        () => fetchData({ cancelToken: source.token }),
        delay,
      );
    }

    return () => {
      if (!delay) {
        source.cancel(`Execution of ${method} ${url} canceled by the client`);
      } else {
        clearTimeout(delayId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, fetchData]);

  return { data, loading, error, refresh };
};

export default useFetch;
