import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

/* eslint no-param-reassign: "error" */

const initialState = {
  bank: {
    isVerified: false,
    expiredTime: null,
  },
  pin: {
    isVerified: false,
    expiredTime: null,
  },
};

export const OTPSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    setOTPBank: (state, action) => {
      state.bank = action.payload;
    },
    resetOTPBank: (state) => {
      state.bank = { isVerified: false, expiredTime: null };
    },
    setOTPPin: (state, action) => {
      state.pin = action.payload;
    },
    resetOTPPin: (state) => {
      state.pin = { isVerified: false, expiredTime: null };
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const { setOTPBank, resetOTPBank, setOTPPin, resetOTPPin } =
  OTPSlice.actions;
export default OTPSlice.reducer;
