/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetChatLists,
  setChatLists,
  setChatToken,
  setFeatures,
  // setUnreadMessageLists,
} from '../redux/features/chat/chatSlice';
import { setNewMessage } from '../redux/features/discuss/discussSlice';
import { setNotification } from '../redux/features/notification/notificationSlice';
import chatService from '../services/chat';

const WebSocketContext = createContext(null);

export const WebSocket = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const { chatToken, chatLists, openChatRoom, features } = useSelector(
    (state) => state?.chat,
  );

  // eslint-disable-next-line no-unused-vars
  const [socket, setSocket] = useState();
  const [token, setToken] = useState();
  const [value, setValue] = useState({});

  const updateUnreadChatLists = () => {
    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in chatLists.data) {
      if (Object.hasOwnProperty.call(chatLists.data, key)) {
        const item = chatLists.data[key];
        if (Number(item.unread_message) > 0) {
          // eslint-disable-next-line
          count = count + 1;
        }
      }
    }
    // dispatch(setUnreadMessageLists(count));
  };

  const fetchQuickReply = () => {
    chatService
      .getQuickReply({ token: chatToken })
      .then((res) => {
        dispatch(
          setFeatures({
            ...features,
            quickReply: res,
          }),
        );
      })
      .catch((err) => {
        console.error('fetchQuickReply', { err });
      });
  };

  const onMessageReceive = (msg) => {
    dispatch(setNewMessage(msg));
  };

  const onNewComplaint = (msg) => {
    const userState = user || {};
    const { username } = userState;
    if (msg?.sender_id !== username) {
      dispatch(
        setNotification({
          data: {
            play: true,
            message: msg,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (user && !chatToken) {
      dispatch(setChatToken(user?.chatToken));
    }

    if (user && !token) {
      setToken(user?.chatToken);
    }

    if (!socket && token) {
      // setSocket(
      //   io(process.env.REACT_APP_API_CHAT_URL, {
      //     transports: ['websocket', 'polling'],
      //     query: {
      //       token,
      //     },
      //   }),
      // );
    }

    if (socket && token) {
      setValue({
        socket,
        token,
      });
    }

    if (socket && chatLists?.data) {
      socket.emit('joinRoom', {
        username: user?.username,
        dataRooms: chatLists?.data?.map((item) => item.conversation_id),
      });
      updateUnreadChatLists();
    }

    if (socket) {
      socket.off('message');
      socket.on('message', (msg) => {
        const list = chatLists?.data?.find(
          (item) => item.conversation_id === msg.conversation_id,
        );
        if (typeof list !== 'undefined') {
          const newObject = { ...list };
          if (
            msg.sender_id !== user?.username &&
            openChatRoom?.conversationId !== msg.conversation_id
          ) {
            newObject.unread_message = Number(newObject.unread_message) + 1;
          }

          const newList = chatLists?.data.filter(
            (item) => item.conversation_id !== msg.conversation_id,
          );

          newList.unshift(newObject);
          dispatch(
            setChatLists({
              ...chatLists,
              data: newList,
            }),
          );
        } else {
          dispatch(resetChatLists());
        }
        dispatch(
          setNotification({
            data: {
              play: true,
              message: msg,
            },
          }),
        );
        dispatch(setNewMessage(msg));
      });

      socket?.off('complaint', onNewComplaint);
      socket?.on('complaint', onNewComplaint);

      socket?.off('messageV2', onMessageReceive);
      socket?.on('messageV2', onMessageReceive);
    }
  }, [user, token, socket, chatToken, chatLists, openChatRoom]);

  useEffect(() => {
    chatToken && fetchQuickReply();
  }, [chatToken]);

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useSocket = () => useContext(WebSocketContext);
