import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import authService from './authService';

/* eslint no-param-reassign: "error" */

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLogin: false,
  message: '',
};

// Login
export const login = createAsyncThunk('auth/login', async (user, thunkApi) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    logout: (state) => {
      state.user = null;
    },
    setAuth: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLogin = true;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset, logout, setAuth } = authSlice.actions;
export default authSlice.reducer;
