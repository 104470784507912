import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import authReducer from './features/auth/authSlice';
import chatReducer from './features/chat/chatSlice';
import contactReducer from './features/contact/contactSlice';
import discussAffiliatorReducer from './features/discuss/discussAffiliatorSlice';
import discussMarketPlaceReducer from './features/discuss/discussMarketplaceSlice';
import discussPageBuilderReducer from './features/discuss/discussPagebuilderSlice';
import discussReducer from './features/discuss/discussSlice';
import featureReducer from './features/feature/featureSlice';
import notificationReducer from './features/notification/notificationSlice';
import otpReducer from './features/otp/otpSlice';
import pageReducer from './features/page/pageSlice';
import screenReducer from './features/screen/screenSlice';
import sidebarReducer from './features/sidebar/sidebarSlice';
import videoReducer from './features/video/videoSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  page: pageReducer,
  screen: screenReducer,
  discussMarketPlace: discussMarketPlaceReducer,
  discussPageBuilder: discussPageBuilderReducer,
  discuss: discussReducer,
  contact: contactReducer,
  notification: notificationReducer,
  sidebar: sidebarReducer,
  otp: otpReducer,
  discussAffiliator: discussAffiliatorReducer,
  feature: featureReducer,
  video: videoReducer,
});

const persistConfig = {
  key: 'seller',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
