import axios from 'axios';

const API_CHAT = `${process.env.REACT_APP_API_CHAT_URL}`;

// sendMessage
const sendMessage = async ({ token, data }) => {
  const url = `${API_CHAT}chat/message`;
  const response = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// postAttachFile
const postAttachFile = async ({ token, conversationId, data }) => {
  const url = `${API_CHAT}chat/file/${conversationId}`;
  const response = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// getListChat
const getListChat = async ({ token, query }) => {
  let url = `${API_CHAT}chat/list?type=personal`;

  // eslint-disable-next-line
  for (const key in query) {
    if (Object.hasOwnProperty.call(query, key)) {
      const value = query[key];
      url += `&${key}=${value}`;
    }
  }
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// getMessages
// eslint-disable-next-line
const getMessages = async ({ conversationId, token, lastKey }) => {
  let url = `${API_CHAT}chat/detail/${conversationId}`;
  if (lastKey) {
    url += `?lastKey=${lastKey}`;
  }
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// getQuickReply
const getQuickReply = async ({ token }) => {
  const url = `${API_CHAT}quickReply`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// postQuickReply
const postQuickReply = async ({ token, data }) => {
  const url = `${API_CHAT}quickReply`;

  const response = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// deleteQuickReply
const deleteQuickReply = async ({ token, data }) => {
  const url = `${API_CHAT}quickReply/${data.id}`;

  const response = await axios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// autoReply
const getAutoReply = async ({ token, isOffline }) => {
  const url = `${API_CHAT}autoReply?is_offline=${isOffline}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const postAutoReply = async ({ token, data }) => {
  const url = `${API_CHAT}autoReply`;
  const response = await axios.post(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

const getNotificationGroup = async ({ token, limit = 10 }) => {
  const url = `${API_CHAT}notifications-group`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: { limit },
  });
  return response.data;
};

const chatService = {
  sendMessage,
  postAttachFile,
  getListChat,
  getMessages,
  getQuickReply,
  postQuickReply,
  deleteQuickReply,
  getAutoReply,
  postAutoReply,
  getNotificationGroup,
};

export default chatService;
