import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  newMessage: {},
};

export const discussSlice = createSlice({
  name: 'discussPageBuilder',
  initialState,
  reducers: {
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    resetNewMessage: (state) => {
      state.newMessage = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const { setNewMessage, resetNewMessage } = discussSlice.actions;

export default discussSlice.reducer;
