import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/seller`;

// login
const login = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);
  return response.data.data;
};

// logout
const logout = async () => true;

const authService = {
  login,
  logout,
};

export default authService;
