/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './const';

export const videoSlice = createSlice({
  name: 'video',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setLastLogin: (state, action) => {
      state.lastLogin = action.payload;
    },
    setLinkVideo: (state, action) => {
      state.linkVideo = action.payload;
    },
    setIsHasBeenWatched: (state, action) => {
      state.isHasBeenWatched = action.payload;
    },
    setPlayed: (state, action) => {
      state.played = action.payload;
    },
    setIsFirstLogin: (state, action) => {
      state.isFirstLogin = action.payload;
    },
    resetState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state.linkVideo = '';
      state.played = '';
      state.isHasBeenWatched = false;
      state.isFirstLogin = false;
      state.lastLogin = null;
      state.username = '';
    },
  },
});

export const {
  setUsername,
  setLastLogin,
  setLinkVideo,
  setIsHasBeenWatched,
  setPlayed,
  setIsFirstLogin,
  resetState,
} = videoSlice.actions;

export default videoSlice.reducer;
