import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';
import {
  INITIAL_LIST,
  INITIAL_STATE,
  INITIAL_TRIGGER_OPEN_CHAT,
} from './const';

export const discussAffiliatorSlice = createSlice({
  name: 'discussAffiliator',
  initialState: { ...INITIAL_STATE },
  /* eslint-disable no-param-reassign */
  reducers: {
    setOpenRoom: (state, action) => {
      state.openRoom = action.payload;
    },
    resetOpenRoom: (state) => {
      state.openRoom = {};
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    resetSearch: (state) => {
      state.search = '';
    },
    setList: (state, action) => {
      state.list = [...action.payload];
    },
    resetList: (state) => {
      state.list = [...INITIAL_LIST];
    },
    setTriggerOpen: (state, action) => {
      state.triggerOpen = { ...state.triggerOpen, ...action.payload };
    },
    resetTriggerOpen: (state) => {
      state.triggerOpen = { ...INITIAL_TRIGGER_OPEN_CHAT };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAuth, (state) => {
      // eslint-disable-next-line no-unused-vars
      state = { ...INITIAL_STATE };
    });
  },
  /* eslint-enable no-param-reassign */
});

export const {
  setOpenRoom,
  resetOpenRoom,
  setSearch,
  resetSearch,
  setList,
  resetList,
  setTriggerOpen,
  resetTriggerOpen,
} = discussAffiliatorSlice.actions;

export default discussAffiliatorSlice.reducer;
