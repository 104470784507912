/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

const initialObject = {
  number: '',
  expired: 0,
};

const initialState = {
  mentor: { ...initialObject, isLoaded: false },
  customerService: initialObject,
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setMentorContact: (state, action) => {
      state.mentor = action.payload;
    },
    setCSContact: (state, action) => {
      state.customerService = action.payload;
    },
    resetMentorContact: (state) => {
      state.mentor = { ...initialObject, isLoaded: false };
    },
    resetCSContact: (state) => {
      state.customerService = initialObject;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const {
  setMentorContact,
  setCSContact,
  resetMentorContact,
  resetCSContact,
} = contactSlice.actions;

export default contactSlice.reducer;
