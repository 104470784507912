/* eslint-disable arrow-body-style */
import './style.scss';

import React from 'react';

import { useIllustration } from '../../../hooks/useIllustration';

const Loader = () => {
  return (
    <div id="loader">
      <img
        src={useIllustration('loader')}
        id="brand-spinner"
        alt="Baleomol Animation"
      />
    </div>
  );
};

export default Loader;
