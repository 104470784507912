import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';
import { INITIAL_MESSAGE_ATTACHMENT, INITIAL_TRIGGER_OPEN_CHAT } from './const';

/* eslint no-param-reassign: "error" */

const initialChatRule = {
  searchChatLists: null,
  isFloating: false,
  openChatFloating: false,
  isFullScreen: false,
  openChatFullScreen: false,
  styles: '',
};

const initialFeatures = {
  quickReply: [],
  autoReply: {},
  autoReplyOffline: {},
};

const initialChatLists = {
  data: [],
  hasMore: false,
  page: 1,
};

// Define State
const initialState = {
  chatToken: '',
  rule: { ...initialChatRule },
  features: { ...initialFeatures },
  unreadMessageLists: {},
  unreadList: {},
  onNewMessage: false,
  newMessage: {},
  openChatRoom: {},
  chatLists: { ...initialChatLists },
  triggerOpen: { ...INITIAL_TRIGGER_OPEN_CHAT },
  attachment: { ...INITIAL_MESSAGE_ATTACHMENT },
  personalChatType: 'member',
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatToken: (state, action) => {
      state.chatToken = action.payload;
    },
    setChatRule: (state, action) => {
      state.rule = {
        ...state.rule,
        ...action.payload,
      };
    },
    resetChatRule: (state) => {
      state.rule = { ...initialChatRule };
    },
    setFeatures: (state, action) => {
      state.features = {
        ...state.features,
        ...action.payload,
      };
    },
    resetFeatures: (state) => {
      state.features = { ...initialFeatures };
    },
    setUnreadMessageLists: (state, action) => {
      state.unreadMessageLists = action.payload;
    },
    resetUnreadMessageLists: (state) => {
      state.unreadMessageLists = 0;
    },
    setUnreadList: (state, action) => {
      state.unreadList = action.payload;
    },
    addUnreadList: (state, action) => {
      const { id, value } = action.payload;
      const prevList =
        typeof state.unreadList === 'object' ? state.unreadList : {};
      state.unreadList = { ...prevList, [id]: (prevList[id] || 0) + value };
    },
    addBulkUnreadList: (state, action) => {
      const prevList =
        typeof state.unreadList === 'object' ? state.unreadList : {};
      state.unreadList = { ...prevList, ...action.payload };
    },
    resetUnreadListById: (state, action) => {
      const { id } = action.payload;
      const prevList =
        typeof state.unreadList === 'object' ? state.unreadList : {};
      state.unreadList = { ...prevList, [id]: 0 };
    },
    resetUnreadList: (state) => {
      state.unreadList = {};
    },
    setOnNewMessage: (state, action) => {
      state.onNewMessage = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    resetNewMessage: (state) => {
      state.newMessage = {};
    },
    setOpenChatRoom: (state, action) => {
      state.openChatRoom = action.payload;
    },
    setAttachment: (state, action) => {
      state.attachment = action.payload;
    },
    resetOpenChatRoom: (state) => {
      state.openChatRoom = {};
    },
    setChatLists: (state, action) => {
      state.chatLists = {
        ...state.chatLists,
        ...action.payload,
      };
    },
    resetChatLists: (state) => {
      state.chatLists = { ...initialChatLists };
    },
    setTriggerOpen: (state, action) => {
      state.triggerOpen = { ...state.triggerOpen, ...action.payload };
    },
    resetTriggerOpen: (state) => {
      state.triggerOpen = { ...INITIAL_TRIGGER_OPEN_CHAT };
    },
    resetAttachment: (state) => {
      state.attachment = { ...INITIAL_MESSAGE_ATTACHMENT };
    },
    setPersonalChatType: (state, action) => {
      state.personalChatType = action.payload;
    },
    resetPersonalChatType: (state) => {
      state.personalChatType = 'member';
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const {
  setChatToken,
  setChatRule,
  resetChatRule,
  setFeatures,
  resetFeatures,
  setUnreadMessageLists,
  resetUnreadMessageLists,
  setOnNewMessage,
  setNewMessage,
  resetNewMessage,
  setOpenChatRoom,
  resetOpenChatRoom,
  setChatLists,
  resetChatLists,
  setTriggerOpen,
  resetTriggerOpen,
  setAttachment,
  resetAttachment,
  setPersonalChatType,
  resetPersonalChatType,
  setUnreadList,
  addUnreadList,
  addBulkUnreadList,
  resetUnreadList,
  resetUnreadListById,
} = chatSlice.actions;
export default chatSlice.reducer;
