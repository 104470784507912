/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './const';

export const featureSlice = createSlice({
  name: 'feature',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setWatchedVideos: (state, action) => {
      state.watchedVideos = {
        ...state.watchedVideos.baleomolAffiliate,
        ...action,
      };
    },
    resetWatchedVideos: (state) => {
      state.watchedVideos = {
        ...INITIAL_STATE.watchedVideos,
      };
    },
  },
});

export const { setWatchedVideos, resetWatchedVideos } = featureSlice.actions;

export default featureSlice.reducer;
