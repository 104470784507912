import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  data: { play: false, message: {} },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.data = action.payload;
    },
    resetNotification: (state) => {
      state.data = initialState;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const { setNotification, resetNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
