import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import clientIO from 'socket.io-client';
import { useAuth } from '../../../hooks/useAuth';
import useFetch from '../../../hooks/useFetch';
import {
  addBulkUnreadList,
  addUnreadList,
} from '../../../redux/features/chat/chatSlice';
import { setNotification } from '../../../redux/features/notification/notificationSlice';
import {
  API_CHAT,
  FETCH_OPTIONS,
  INITIAL_CONTEXT_VALUE,
  INITIAL_WS_CONFIG,
} from '../const';

const SocketContext = createContext();
export const SocketProvider = ({ children }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [socket, setSocket] = useState(null);
  const [typing, setTyping] = useState(null);
  const [refreshDiscussion, setRefreshDiscussion] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [contextValue, setContextValue] = useState({
    ...INITIAL_CONTEXT_VALUE,
  });
  const { data, loading } = useFetch({
    ...FETCH_OPTIONS,
    token,
  });

  const resetRefreshDiscussion = useCallback(() => {
    setRefreshDiscussion(null);
  }, []);

  const modifyUnreadList = (response) => {
    const username = user?.username || '';
    const { conversationId, senderId } = response;

    if (senderId !== username) {
      dispatch(
        addUnreadList({
          id: conversationId,
          value: 1,
        }),
      );
    }
  };

  const newMessageHandler = (response) => {
    setNewMessage(response);
    modifyUnreadList(response);
  };

  const newMessageGroupHandler = (response) => {
    if (response?.refresh) {
      setRefreshDiscussion(response);
    }
  };

  const newTypingHandler = (response) => {
    setTyping(response);
  };

  const newComplaintHandler = (response) => {
    const username = user?.username || '';
    if (response?.sender_id !== username) {
      dispatch(
        setNotification({
          data: {
            play: true,
            message: response,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!token && user) {
      const { chatToken } = user || {};
      setToken(chatToken);
    }

    if (!socket && token) {
      const query = { token };
      const wsConfig = { ...INITIAL_WS_CONFIG, query };
      setSocket(clientIO(API_CHAT, wsConfig));
    }

    if (socket && token) {
      setContextValue((prev) => {
        return {
          ...prev,
          socket,
        };
      });
    }

    if (socket) {
      socket.off('messageV2', newMessageHandler);
      socket.on('messageV2', newMessageHandler);

      socket.off('messageGroup', newMessageGroupHandler);
      socket.on('messageGroup', newMessageGroupHandler);

      socket.off('complaint', newComplaintHandler);
      socket.on('complaint', newComplaintHandler);

      socket.off('notifyTyping', newTypingHandler);
      socket.on('notifyTyping', newTypingHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, socket, user]);

  useEffect(() => {
    let timeoutID = null;

    setContextValue((prev) => {
      return { ...prev, typing };
    });

    if (typing) {
      timeoutID = setTimeout(() => {
        setContextValue((prev) => {
          return { ...prev, typing: {} };
        });
      }, 3000);
    }

    return () => clearTimeout(timeoutID);
  }, [typing]);

  useEffect(() => {
    let timeoutID = null;
    let notifTimeoutId = null;

    setContextValue((prev) => {
      return { ...prev, newMessage };
    });

    if (newMessage) {
      const username = user?.username || '';
      if (newMessage?.senderId !== username) {
        notifTimeoutId = setTimeout(() => {
          dispatch(
            setNotification({
              data: {
                play: true,
                message: {},
              },
            }),
          );
        }, 500);
      }

      timeoutID = setTimeout(() => {
        setContextValue((prev) => {
          return { ...prev, newMessage: null };
        });
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutID);
      clearTimeout(notifTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  useEffect(() => {
    if (data?.success && !loading) {
      const response = data?.data || [];
      const list = response?.data || [];
      const unreadList = list.reduce((prev, current) => {
        Object.assign(prev, { [current.id]: current.unread });
        return prev;
      }, {});

      dispatch(addBulkUnreadList(unreadList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    setContextValue((prev) => {
      return {
        ...prev,
        refreshDiscussion,
        resetRefreshDiscussion,
      };
    });
  }, [refreshDiscussion, resetRefreshDiscussion]);

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => useContext(SocketContext);

export default useSocket;
