/* eslint-disable camelcase */
import empty from '../assets/images/illustrations/empty-illustration.png';
import landingpageSuccessRegister from '../assets/images/illustrations/landingpage-success-register.png';
import loader from '../assets/images/illustrations/loadinganimation.gif';

const illustrations = {
  empty,
  landingpageSuccessRegister,
  loader,
};

export const useIllustration = (name) => illustrations[name];
