import { createSlice } from '@reduxjs/toolkit';
/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  appName: 'Supplier Baleomol',
  pageTitle: '',
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
  },
});

export const { setPageTitle } = pageSlice.actions;
export default pageSlice.reducer;
