import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

/* eslint no-param-reassign: "error" */

const initialLists = {
  data: [],
  hasMore: false,
  page: 1,
};

// Define State
const initialState = {
  openRoom: {},
  search: '',
  lists: initialLists,
};

export const discussPageBuilderSlice = createSlice({
  name: 'discussPageBuilder',
  initialState,
  reducers: {
    setOpenRoom: (state, action) => {
      state.openRoom = action.payload;
    },
    resetOpenRoom: (state) => {
      state.openRoom = {};
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    resetSearch: (state) => {
      state.search = '';
    },
    setLists: (state, action) => {
      state.lists = {
        ...state.lists,
        ...action.payload,
      };
    },
    resetLists: (state) => {
      state.lists = initialLists;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const {
  setOpenRoom,
  resetOpenRoom,
  setSearch,
  resetSearch,
  setLists,
  resetLists,
} = discussPageBuilderSlice.actions;

export default discussPageBuilderSlice.reducer;
