import 'react-toastify/dist/ReactToastify.css';

import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Loader from '../components/organisms/Loader';

// Disable Feature Discuss and Chat

const AuthShared = loadable(() => import('../pages/AuthShared'));
const Home = loadable(() => import('../pages/Home'));
const FloatingChatV2 = loadable(() =>
  import('../components/organisms/FloatingChatV2'),
);
const NotFound = loadable(() => import('../pages/NotFound'));
const Auth = loadable(() => import('../pages/Auth'));
const MarketplaceVideos = loadable(() =>
  import('../pages/Videos/MarketplaceVideos'),
);
const PageBuilderVideos = loadable(() =>
  import('../pages/Videos/PageBuilderVideos'),
);
const MarketplaceOrders = loadable(() =>
  import('../pages/Orders/MarketplaceOrders'),
);
const MarketplaceBulkOrders = loadable(() =>
  import('../pages/Orders/MarketplaceBulkOrders'),
);
const PageBuilderOrders = loadable(() =>
  import('../pages/Orders/PageBuilderOrders'),
);
// const AffiliateOrders = loadable(() =>
//   import('../pages/Orders/AffiliateOrders'),
// );
// const AffiliateOrderDetail = loadable(() =>
//   import('../pages/Orders/AffiliateOrders/Detail'),
// );
const Followers = loadable(() => import('../pages/Followers'));
const Finance = loadable(() => import('../pages/Finance'));
const StoreSetting = loadable(() =>
  import('../pages/StoreSetting/StoreSetting'),
);
const AccountSetting = loadable(() => import('../pages/AccountSetting'));
const Chat = loadable(() => import('../pages/Chat'));
const Discuss = loadable(() => import('../pages/Discuss'));
const ChatSettings = loadable(() => import('../pages/Chat/ChatSettings'));
// const ChatFullScreen = loadable(() => import('../pages/ChatFullScreen'));
const DashboardMarketplace = loadable(() =>
  import('../pages/Dashboard/DashboardMarketplace'),
);
const DashboardPageBuilder = loadable(() =>
  import('../pages/Dashboard/DashboardPageBuilder'),
);
// const DashboardAffiliate = loadable(() =>
//   import('../pages/Dashboard/DashboardAffiliate'),
// );
const Notification = loadable(() => import('../pages/Notification'));
const Wallet = loadable(() => import('../pages/Finance/Wallet'));
const AccountBank = loadable(() => import('../pages/Finance/AccountBank'));
const Faq = loadable(() => import('../pages/Faq'));
const MarketplaceOrderDetail = loadable(() =>
  import('../pages/Orders/MarketplaceOrders/Detail'),
);
const AddProduct = loadable(() => import('../pages/Products/AddProduct'));
const DetailProduct = loadable(() => import('../pages/Products/DetailProduct'));
const MarketplaceProduct = loadable(() =>
  import('../pages/Products/MarketplaceProduct'),
);
// const PageBuilderProduct = loadable(() =>
//   import('../pages/Products/PageBuilderProduct'),
// );
const PageBuilderOrderDetail = loadable(() =>
  import('../pages/Orders/PageBuilderOrders/Detail'),
);
const CourierConfiguration = loadable(() =>
  import('../pages/Setting/CourierConfiguration'),
);
const SettingAddress = loadable(() =>
  import('../pages/Setting/SettingAddress'),
);
// const AffiliateProduct = loadable(() =>
//   import('../pages/Products/AffiliateProduct'),
// );

const ProductOptimation = loadable(() => import('../pages/ProductOptimation'));
const AudioPlayer = loadable(() =>
  import('../components/organisms/AudioPlayer'),
);
const Feeds = loadable(() => import('../pages/Setting/Feeds'));
const FeedAdd = loadable(() => import('../pages/Setting/Feeds/FeedAdd'));
const SystemVideos = loadable(() => import('../pages/Videos/SystemVideos'));
const PascaBayar = loadable(() => import('../pages/Finance/PascaBayar'));
const PascaBayarDetail = loadable(() =>
  import('../pages/Finance/PascaBayar/Detail'),
);
const PascaBayarPaymentHistory = loadable(() =>
  import('../pages/Finance/PascaBayar/PaymentHistory'),
);
const ChatFullScreenV2 = loadable(() => import('../pages/ChatFullScreenV2'));
const EditProduct = loadable(() => import('../pages/Products/EditProduct'));
const MitraOrders = loadable(() => import('../pages/Orders/MitraOrders'));
const MitraOrderDetail = loadable(() =>
  import('../pages/Orders/MitraOrders/Detail'),
);

// google analytic
ReactGA.initialize('G-S141HSTGP6');
const Router = () => {
  const exact = true;
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [location]);
  return (
    <>
      <Routes>
        <Route
          exact={exact}
          path="/"
          element={<Home fallback={<Loader />} />}
        />
        <Route
          exact={exact}
          path="/auth-shared"
          element={<AuthShared fallback={<Loader />} />}
        />
        <Route path="/dashboard">
          <Route
            exact={exact}
            path="marketplace"
            element={<DashboardMarketplace fallback={<Loader />} />}
          />
          <Route
            exact={exact}
            path="page-builder"
            element={<DashboardPageBuilder fallback={<Loader />} />}
          />
          {/* <Route
            exact={exact}
            path="affiliate"
            element={<DashboardAffiliate fallback={<Loader />} />}
          /> */}
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        <Route path="/video">
          <Route
            exact
            path="tutorial"
            element={<MarketplaceVideos fallback={<Loader />} />}
          />
          <Route
            exact
            path="tutorial-system"
            element={<SystemVideos fallback={<Loader />} />}
          />
          <Route
            exact
            path="tutorial-page-builder"
            element={<PageBuilderVideos fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        <Route path="/order">
          <Route
            exact
            path=":status/:typeCheckout/:page"
            element={<MarketplaceOrders fallback={<Loader />} />}
          />
          <Route
            exact
            path="detail/:id"
            element={<MarketplaceOrderDetail fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        <Route path="/order-bulk">
          <Route
            exact
            path=":status/:page"
            element={<MarketplaceBulkOrders fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        <Route path="/order-page-builder">
          <Route
            exact
            path=":status/:page"
            element={<PageBuilderOrders fallback={<Loader />} />}
          />
          <Route
            exact
            path="detail/:id"
            element={<PageBuilderOrderDetail fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        {/* <Route path="/order-affiliate">
          <Route
            exact
            path=":status/:page"
            element={<AffiliateOrders fallback={<Loader />} />}
          />
          <Route
            exact
            path="detail/:id"
            element={<AffiliateOrderDetail fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route> */}

        <Route path="/order-mitra">
          <Route
            exact
            path=":status/:page"
            element={<MitraOrders fallback={<Loader />} />}
          />
          <Route
            exact
            path="detail/:id"
            element={<MitraOrderDetail fallback={<Loader />} />}
          />
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        <Route path="/product">
          <Route
            exact
            path=":status"
            element={<MarketplaceProduct fallback={<Loader />} />}
          />
          <Route
            exact
            path="add"
            element={<AddProduct fallback={<Loader />} />}
          />
          <Route
            exact
            path=":productId/detail"
            element={<DetailProduct fallback={<Loader />} />}
          />
          <Route
            exact
            path=":productId/edit"
            element={<EditProduct fallback={<Loader />} />}
          />
        </Route>

        <Route path="/product/optimation">
          <Route
            exact
            path=":slug/:status"
            element={<ProductOptimation fallback={<Loader />} />}
          />
        </Route>

        <Route
          exact
          path="/followers"
          element={
            <Followers
              title="Pengikut"
              type="mp"
              key="mp"
              fallback={<Loader />}
            />
          }
        />
        <Route
          exact
          path="/followers/page-builder"
          element={
            <Followers title="Pengikut Page Builder" type="lp" key="lp" />
          }
        />
        <Route
          exact
          path="/wallet"
          element={<Finance fallback={<Loader />} />}
        />
        <Route
          exact
          path="/bank-account"
          element={<Finance fallback={<Loader />} />}
        />
        <Route
          exact
          path="/notification"
          element={<Notification fallback={<Loader />} />}
        />

        <Route path="/finance">
          <Route path="wallet" element={<Wallet fallback={<Loader />} />}>
            <Route
              exact
              path=":slug"
              element={<Wallet fallback={<Loader />} />}
            />
            <Route
              exact
              path="*"
              element={<NotFound fallback={<Loader />} />}
            />
          </Route>
          <Route
            exact
            path="account-bank"
            element={<AccountBank fallback={<Loader />} />}
          />
          <Route path="pasca-bayar">
            <Route
              exact
              path=":page"
              element={<PascaBayar fallback={<Loader />} />}
            />
            <Route
              exact
              path="detail/:id"
              element={<PascaBayarDetail fallback={<Loader />} />}
            />
            <Route
              exact
              path="payment-history/:id"
              element={<PascaBayarPaymentHistory fallback={<Loader />} />}
            />
            <Route
              exact
              path="*"
              element={<NotFound fallback={<Loader />} />}
            />
          </Route>
          <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        </Route>

        {/* <Route exact path="/finance/wallet" element={<Wallet  fallback={<Loader />}/>} />
          <Route exact path="/finance/wallet/:slug" element={<Wallet  fallback={<Loader />}/>} />
          <Route exact path="/finance/account-bank" element={<AccountBank  fallback={<Loader />}/>} /> */}

        <Route exact path="/faq" element={<Faq fallback={<Loader />} />} />
        <Route path="/setting">
          <Route
            path="store/:page"
            element={<StoreSetting fallback={<Loader />} />}
          />
          <Route
            exact
            path="account/:page"
            element={<AccountSetting fallback={<Loader />} />}
          />
          <Route
            exact
            path="shipping-service"
            element={<CourierConfiguration fallback={<Loader />} />}
          />
          <Route
            exact
            path="address"
            element={<SettingAddress fallback={<Loader />} />}
          />
          <Route path="tasks" />
        </Route>
        <Route path="/feeds">
          <Route
            exact
            path="my-feeds"
            element={<Feeds fallback={<Loader />} />}
          />
          <Route exact path="add" element={<FeedAdd fallback={<Loader />} />} />
          {/* <Route exact path="detail/:id" element={<Feed  fallback={<Loader />}/>} /> */}
        </Route>
        <Route exact path="/auth/*" element={<Auth fallback={<Loader />} />} />
        <Route exact path="/chat/*" element={<Chat fallback={<Loader />} />} />
        <Route
          exact
          path="/chat/fs"
          element={<ChatFullScreenV2 fallback={<Loader />} />}
        />
        <Route
          exact
          path="/chat/settings/:slug"
          element={<ChatSettings fallback={<Loader />} />}
        />
        {/* <Route
          exact
          path="/chat-full-screen"
          element={<ChatFullScreen fallback={<Loader />} />}
        /> */}
        <Route
          exact
          path="/discuss/:slug"
          element={<Discuss fallback={<Loader />} />}
        />
        <Route
          exact
          path="/discuss/:slug/:type"
          element={<Discuss fallback={<Loader />} />}
        />
        <Route exact path="*" element={<NotFound fallback={<Loader />} />} />
        <Route
          exact
          path="/not-found"
          element={<NotFound fallback={<Loader />} />}
        />
      </Routes>

      <FloatingChatV2 />
      <AudioPlayer />
      <ToastContainer />
    </>
  );
};

export default Router;
