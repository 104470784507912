export const INITIAL_LIST = [];

export const INITIAL_TRIGGER_OPEN_CHAT = {
  chatId: '',
  trigger: false,
};

export const INITIAL_STATE = {
  openRoom: {},
  search: '',
  list: [...INITIAL_LIST],
  triggerOpen: { ...INITIAL_TRIGGER_OPEN_CHAT },
};
