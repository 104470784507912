import { createSlice } from '@reduxjs/toolkit';
/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  isMobile: false,
};

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsMobile } = screenSlice.actions;
export default screenSlice.reducer;
