export const INITIAL_TRIGGER_OPEN_CHAT = {
  username: '',
  trigger: false,
};

export const INITIAL_MESSAGE_ATTACHMENT = {
  type: '',
  conversationId: '',
  username: '',
  data: {},
  show: false,
};

export const AVAILABLE_MESSAGE_TYPE = ['order', 'product'];
